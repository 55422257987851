import { MaterialSymbolsIcon, NotificationCircleFilledIcon, NotificationIcon } from '@lib/icons';
import { FlexBox, RedBadgeIconWrapper } from '@lib/react-components';
import { useTheme } from '@mui/material';
import { matchPath, useHistory } from 'react-router-dom';

import { useGetNumberOfUnreadAnnouncements } from 'features/Announcements/graphql/__generated__/GetNumberOfUnreadAnnouncements.query';
import { useRootModelPath } from 'features/Root/model';
import { useUserModelStore } from 'features/Users/model';
import { ROUTE_PATHS } from 'routes/constants';

export const AnnouncementIcon = (): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const { makeGoToPath, getPath } = useRootModelPath();
  const isAnnouncementsPath = !!matchPath(history.location.pathname, getPath({ path: ROUTE_PATHS.announcements }));
  const { activeProfile } = useUserModelStore();
  const { data: announcementsData } = useGetNumberOfUnreadAnnouncements({
    skip: activeProfile?.hasPostRegister || isAnnouncementsPath,
  });

  const cursorValue = isAnnouncementsPath ? 'default' : 'pointer';

  const onClick: VoidFunction = makeGoToPath({ path: ROUTE_PATHS.announcements });

  return (
    <FlexBox
      position="relative"
      alignItems="center"
      justifyContent="center"
      sx={{ cursor: cursorValue }}
      borderRadius="50%"
      onClick={onClick}
      mx={`${isAnnouncementsPath ? 6 : 10}px`}
    >
      <RedBadgeIconWrapper
        top={0}
        right={1}
        visible={!isAnnouncementsPath && !!announcementsData?.getNumberOfUnreadAnnouncements}
      >
        <MaterialSymbolsIcon
          fontSize={isAnnouncementsPath ? 36 : 28}
          cursor={cursorValue}
          icon={
            isAnnouncementsPath ? (
              <NotificationCircleFilledIcon fill={theme.palette.action.disabled} />
            ) : (
              <NotificationIcon fontWeight="300" />
            )
          }
          hover={
            isAnnouncementsPath
              ? undefined
              : {
                  fill: theme.palette.black.main,
                }
          }
        />
      </RedBadgeIconWrapper>
    </FlexBox>
  );
};
