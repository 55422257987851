import { lazy } from 'react';

import { ROUTE_PATHS } from '../constants';
import { patientSelfRegistrationPageGuard } from '../guards';
import { IRoute } from '../types';

import { publicRoutes } from './publicRoutes';

import { ProfileType } from '__generated__/types';

const DASHBOARD_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.dashboard,
    component: lazy(() => import('pages/Dashboard')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.dashboardCommon,
    component: lazy(() => import('pages/Dashboard')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
];

const CHAT_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.chatCreation,
    component: lazy(() => import('pages/Chats/Create')),
    exact: true,
    portalSettingsGuards: [patientSelfRegistrationPageGuard],
  },
  {
    path: ROUTE_PATHS.chatOneToOne,
    component: lazy(() => import('pages/Chats/OneToOne')),
    exact: true,
    portalSettingsGuards: [patientSelfRegistrationPageGuard],
  },
  {
    path: ROUTE_PATHS.chatClinic,
    component: lazy(() => import('pages/Chats/Clinic')),
    exact: true,
    portalSettingsGuards: [patientSelfRegistrationPageGuard],
  },
  {
    path: ROUTE_PATHS.chatsOneToOneList,
    component: lazy(() => import('pages/Chats/List/OneToOne')),
    exact: true,
    portalSettingsGuards: [patientSelfRegistrationPageGuard],
  },
  {
    path: ROUTE_PATHS.chatsClinicList,
    component: lazy(() => import('pages/Chats/List/Clinic')),
    exact: true,
    portalSettingsGuards: [patientSelfRegistrationPageGuard],
  },
];

const MEDICAL_DATA_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.patientsByIdResolveTab,
    component: lazy(() => import('pages/MedicalData/ResolvePatientTab')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.patientAindoFileParameters,
    component: lazy(() => import('pages/MedicalData/AindoFileParameters')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR],
  },
  ...[ROUTE_PATHS.patientsSelf, ROUTE_PATHS.patientsSelfPreselectedSection].map(route => ({
    path: route,
    component: lazy(() => import('pages/MedicalData/SelfMedicalData')),
    exact: true,
    authorizedRoles: [ProfileType.PATIENT],
  })),
  ...[ROUTE_PATHS.patientsById, ROUTE_PATHS.patientsByIdPreselectedSection].map(route => ({
    path: route,
    component: lazy(() => import('pages/MedicalData/PatientMedicalData')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  })),
];

const PATIENT_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.patientsCreate,
    component: lazy(() => import('pages/Patient/Create')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.patientInvoices,
    component: lazy(() => import('pages/Patient/Invoices')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.patientCareTeam,
    component: lazy(() => import('pages/Patient/CareTeam')),
    exact: true,
    authorizedRoles: [ProfileType.PATIENT],
  },
  {
    path: ROUTE_PATHS.patientsByIdCancelledFiles,
    component: lazy(() => import('pages/Patient/CancelledFiles')),
    exact: true,
    authorizedRoles: [ProfileType.PATIENT],
  },
];

const AFFILIATED_REQUEST_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.affiliatedDoctor,
    component: lazy(() => import('pages/Request/Doctor/Affiliated')),
    exact: true,
    authorizedRoles: [ProfileType.PATIENT],
  },
  {
    path: ROUTE_PATHS.affiliatedDoctorService,
    component: lazy(() => import('pages/Request/Doctor/AffiliatedService')),
    exact: true,
    authorizedRoles: [ProfileType.PATIENT],
  },
];

const SSN_CLINIC_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.requestSsnClinics,
    component: lazy(() => import('pages/Request/SsnClinic/Request')),
    exact: true,
    authorizedRoles: [ProfileType.PATIENT],
  },
];

const REQUEST_PAYMENT_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.payment,
    component: lazy(() => import('pages/Request/Payment')),
    exact: true,
  },
];

const DOCTOR_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.patientsList,
    component: lazy(() => import('pages/Doctor/PatientList')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.reservationClinic,
    component: lazy(() => import('pages/Doctor/Clinic/View')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.reservationClinicService,
    component: lazy(() => import('pages/Doctor/Clinic/ReservationClinicService')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.calendar,
    component: lazy(() => import('pages/Doctor/Calendar')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR],
  },
  {
    path: ROUTE_PATHS.clinics,
    component: lazy(() => import('pages/Doctor/Clinic/List')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
];

const INTRAMED_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.intramedCreate,
    component: lazy(() => import('pages/IntramedCreate')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.intramedById,
    component: lazy(() => import('pages/IntramedThread')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.intramedList,
    component: lazy(() => import('pages/IntramedList')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
];

const INVITE_ROUTES: IRoute[] = [
  ...[ROUTE_PATHS.inviteCreation, ROUTE_PATHS.carTNetworkInviteUser].map(route => ({
    path: route,
    component: lazy(() => import('pages/Invite/Create')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  })),
  {
    path: ROUTE_PATHS.inviteDetails,
    component: lazy(() => import('pages/Invite/View')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.invites,
    component: lazy(() => import('pages/Invite/List')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
];

const INFO_THREAD_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.infoById,
    component: lazy(() => import('pages/InfoThread/View')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.infoList,
    component: lazy(() => import('pages/InfoThread/List')),
    exact: true,
  },
];

const AFFILIATION_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.settingsAffiliationById,
    component: lazy(() => import('pages/Affiliations/View')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR],
  },
];

const CART_NETWORK_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.carTNetworkList,
    component: lazy(() => import('pages/CarTNetwork/List')),
    exact: true,
  },

  {
    path: ROUTE_PATHS.carTNetworkById,
    component: lazy(() => import('pages/CarTNetwork/View')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR],
  },
];

const EQUIPE_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.equipesEditById,
    component: lazy(() => import('pages/Equipe/AddMember')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.equipesCreate,
    component: lazy(() => import('pages/Equipe/Create')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },

  {
    path: ROUTE_PATHS.equipesById,
    component: lazy(() => import('pages/Equipe/View')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.equipesList,
    component: lazy(() => import('pages/Equipe/List')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
];

const REDIRECT_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.fileRedirect,
    component: lazy(() => import('pages/Redirect/File')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.videoMeetingRedirectTo,
    component: lazy(() => import('pages/Redirect/VideoMeeting')),
    exact: true,
  },

  {
    path: ROUTE_PATHS.videoVisitRedirectTo,
    component: lazy(() => import('pages/Redirect/VideoVisit')),
    exact: true,
  },
];

const PROFILE_ROUTES: IRoute[] = [
  ROUTE_PATHS.settingsTermsAndConditions,
  ROUTE_PATHS.settingsServiceManagement,
  ROUTE_PATHS.settingsAffiliations,
  ROUTE_PATHS.settings,
].map(route => ({
  path: route,
  component: lazy(() => import('pages/ProfileSettings')),
  exact: true,
}));

const ACCOUNTING_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.accountingEntries,
    component: lazy(() => import('pages/Accounting/View')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR],
  },

  {
    path: ROUTE_PATHS.accounting,
    component: lazy(() => import('pages/Accounting/List')),
    exact: true,
  },
];

const REMINDER_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.reminders,
    component: lazy(() => import('pages/Reminder/List')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.reminder,
    component: lazy(() => import('pages/Reminder/View')),
    exact: true,
  },
];

const SURVEY_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.surveyList,
    component: lazy(() => import('pages/Survey/List')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.surveyIssueCreation,
    component: lazy(() => import('pages/Survey/Create')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.surveyFormPage,
    component: lazy(() => import('pages/Survey/Form')),
    exact: true,
    authorizedRoles: [ProfileType.DOCTOR, ProfileType.PATIENT, ProfileType.NON_DOCTOR],
  },
  {
    path: ROUTE_PATHS.surveyDetails,
    component: lazy(() => import('pages/Survey/View')),
    exact: true,
  },
];

const PORTAL_DOCUMENTS_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.portalDocuments,
    component: lazy(() => import('pages/PortalDocuments')),
    exact: true,
  },
];

const ANNOUNCEMENTS_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.announcements,
    component: lazy(() => import('pages/Announcements')),
    exact: true,
  },
];

export const authorizedRoutes: IRoute[] = [
  ...DASHBOARD_ROUTES,
  ...PROFILE_ROUTES,
  ...CHAT_ROUTES,
  // needs to stay above MEDICAL_DATA_ROUTES
  ...PATIENT_ROUTES,
  ...MEDICAL_DATA_ROUTES,
  ...AFFILIATED_REQUEST_ROUTES,
  ...SSN_CLINIC_ROUTES,
  ...REQUEST_PAYMENT_ROUTES,
  ...DOCTOR_ROUTES,
  ...INTRAMED_ROUTES,
  ...INVITE_ROUTES,
  ...INFO_THREAD_ROUTES,
  ...AFFILIATION_ROUTES,
  ...CART_NETWORK_ROUTES,
  ...EQUIPE_ROUTES,
  ...REDIRECT_ROUTES,
  ...ACCOUNTING_ROUTES,
  ...REMINDER_ROUTES,
  ...SURVEY_ROUTES,
  ...PORTAL_DOCUMENTS_ROUTES,
  ...ANNOUNCEMENTS_ROUTES,
  ...publicRoutes,
  {
    path: ROUTE_PATHS[404],
    component: lazy(() => import('pages/WrongPath')),
    exact: true,
  },
];
