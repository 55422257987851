import { SetFilterFunction, useFiltersWithPagination } from '@lib/react-components';
import { convertStringToBoolean } from '@lib/utils';

import { IDoctorClinicsQueryFilters, IDoctorClinicsFilters } from '../DoctorClinicsFilters/types';

import { GsdHsrClinicSubType, Specialization } from '__generated__/types';

interface IUseDoctorClinicsFiltersReturn {
  search: string;
  setSearch: (search: string) => void;
  filters: IDoctorClinicsFilters;
  setFilter: SetFilterFunction;
  clearFilters: VoidFunction;
}

type UseDoctorClinicsFilters = () => IUseDoctorClinicsFiltersReturn;

export const useDoctorClinicsFilters: UseDoctorClinicsFilters = () => {
  const {
    setSearch,
    search,
    setFilter,
    clearFilters,
    filters: { specializations, types, showBookableForPatient, showAllClinics },
  } = useFiltersWithPagination<IDoctorClinicsQueryFilters>({
    specializations: null,
    types: null,
    showBookableForPatient: null,
    showAllClinics: null,
  });
  const filters = {
    specializations: (specializations?.split(',') || []) as Specialization[],
    types: (types?.split(',') || []) as GsdHsrClinicSubType[],
    showBookableForPatient: convertStringToBoolean(showBookableForPatient),
    showAllClinics: convertStringToBoolean(showAllClinics),
  };

  return {
    search,
    setSearch,
    filters,
    setFilter,
    clearFilters,
  };
};
